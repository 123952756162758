import { useEffect, useState } from "react"
import localStorageMemory from "localstorage-memory"

const windowGlobal = typeof window !== "undefined" && window
const localAdapter = windowGlobal
  ? windowGlobal.localStorage
  : localStorageMemory
const keyPrefix = "up2us:"

const useStateWithLocalStorage = (localStorageKey, defaultValue = "") => {
  const key = keyPrefix + localStorageKey
  const [value, setValue] = useState(
    localAdapter.getItem(key)
      ? JSON.parse(localAdapter.getItem(key))
      : defaultValue,
  )
  useEffect(() => {
    localAdapter.setItem(key, JSON.stringify(value))
  }, [value])
  return [value, setValue]
}

const useQuiz = quizNumber => {
  return useStateWithLocalStorage(`quiz:${quizNumber}`, {})
}

const Storage = {
  useQuiz,
}

export default Storage

import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 2
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"THREE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={
          "What is the belief that we can always grow and do new things with confidence, effort, persistence, and time?"
        }
        answers={[
          "Fixed mindset",
          "Growth mindset",
          "Opportunity mindset",
          "Athlete mindset",
        ]}
        correctAnswerNumber={1}
        answerDetail={
          "Researcher Carol Dweck defines the growth mindset as knowing that we can always do new things with confidence, effort and persistence. The opposite of this would be the fixed mindset, where we can’t improve or grow what we have in terms of talent, brain power and innate ability over time."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 3, 5)

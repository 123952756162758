import React, { useState } from "react"
import { Box, Flex } from "reflexbox"
import { css } from "@emotion/core"
import { Heading, Text } from "~/components/common/Typography"
import Button from "~/components/common/Button"
import { Link } from "gatsby"
import Storage from "~/services/store"
import useDimensions from "react-use-dimensions"
import Copyright from "~/components/common/Copyright"

const letters = ["A", "B", "C", "D", "E", "F"]

const Question = ({
                    answer,
                    index,
                    onSelect,
                    selected,
                    correct,
                    answered,
                    ...props
                  }) => {
  const meSelected = index === selected
  return (
    <Flex
      alignItems={"center"}
      onClick={() => onSelect(index)}
      {...props}
      css={{
        display:
          !answered || (answered && selected === index) ? "flex" : "none",
      }}
    >
      <Flex
        height={["42px", "66px"]}
        width={["42px", "66px"]}
        minWidth={["42px", "66px"]}
        minHeight={["42px", "66px"]}
        justifyContent={"center"}
        alignItems={"center"}
        mr={[5, 6]}
        bg={meSelected ? "black" : "white"}
        css={css`
          border: 2px solid #000000;
          border-radius: 50%;
          cursor: pointer;
        `}
      >
        <Text
          fontSize={["16px", "24px"]}
          lineHeight={["19px", "29px"]}
          color={meSelected ? "white" : "black"}
        >
          {letters[index]}
        </Text>
      </Flex>
      <Box flexGrow={1}>
        <Text fontSize={["16px", "24px"]} lineHeight={["19px", "29px"]} my={0}>
          {answer}
        </Text>
      </Box>
      {answered && (
        <Text
          fontFamily={"AdineuePROBlackWeb"}
          fontSize={["16px", "24px"]}
          lineHeight={["19px", "29px"]}
          ml={4}
          color={correct === index ? "seafoam" : "red"}
        >
          {answered && correct === index ? "CORRECT" : "INCORRECT"}
        </Text>
      )}
    </Flex>
  )
}

const Quiz = ({
                quizNumber,
                questionsTotal,
                questionNumber,
                question,
                answers,
                correctAnswerNumber,
                answerDetail,
                nextLink,
                nextText,
                children,
                ...props
              }) => {
  let [selected, setSelected] = useState(null)
  let [correct, setCorrect] = useState(null)
  let [answered, setAnswered] = useState(false)
  let [quiz, setQuiz] = Storage.useQuiz(quizNumber)
  const [ref, { x, height, width }] = useDimensions()

  const checkAnswer = () => {
    if (selected === null) {
      return
    }
    setCorrect(correctAnswerNumber)
    setAnswered(true)

    setQuiz(s =>
      Object.assign({}, quiz, {
        [questionNumber]: selected === correctAnswerNumber,
      }),
    )
  }

  return (
    <Flex
      flexDirection={["column"]}
      maxWidth={["100%", "tablet", "desktop"]}
      mx={"auto"}
      px={["16px", "32px"]}
      minHeight={"100%"}
    >
      <Flex
        pt={[3, 4, 5]}
        pb={6}
        flexDirection={"column"}
        width={["100%", "600px"]}
        maxWidth={["100%", "600px"]}
        mx={"auto"}
        flexGrow={1}
        flexShring={1}
      >
        <Text
          fontFamily={"AdineuePROCondRegular"}
          fontSize={["16px", "20px"]}
          lineHeight={["19px", "22px"]}
          color={"black"}
        >
          QUESTION {questionNumber}/{questionsTotal}
        </Text>
        <Heading
          textAlign={"left"}
          fontFamily={"AdineuePROBoldWeb"}
          fontSize={["18px", "24px"]}
          lineHeight={["27px", "36px"]}
          css={css`
            text-transform: none;
          `}
        >
          {question}
        </Heading>
        <Box minHeight={`${height}px`}>
          <Box
            width={"100%"}
            mt={4}
            css={css`
              display: grid;
              grid-auto-rows: 1fr;
            `}
            ref={ref}
          >
            {answers.map((answer, index) => (
              <Question
                py={[4, 4]}
                answer={answer}
                index={index}
                correct={correct}
                selected={selected}
                answered={answered}
                onSelect={setSelected}
                key={index}
              ></Question>
            ))}
          </Box>
          {answered ? (
            <Box my={4} flexGrow={1}>
              <Text
                mt={0}
                fontSize={["16px", "20px", "18px"]}
                lineHeight={["24px", "24px", "27px"]}
              >
                {correctAnswerNumber != selected
                  ? `The correct answer is ${letters[correctAnswerNumber]}, ${answers[correctAnswerNumber]}. `
                  : ""}
                {answerDetail}
              </Text>
            </Box>
          ) : null}
        </Box>

        {answered ? (
          <Link
            to={nextLink}
            css={css`
              text-decoration: none;

              &:hover {
                text-decoration: none;
              }
            `}
          >
            <Button mt="6" width={"100%"}>
              {nextText ? nextText : "NEXT QUESTION"}
            </Button>
          </Link>
        ) : (
          <Box>
            <Button
              disabled={selected === null}
              mt="6"
              width={"100%"}
              onClick={() => checkAnswer()}
            >
              CHECK ANSWER
            </Button>
          </Box>
        )}
      </Flex>
      <Flex>
        <Copyright pt={[6]}/>
      </Flex>
    </Flex>
  )
}

const QuizResults = ({ text, quizNumber, nextLink, children, ...props }) => {
  let [quiz, setQuiz] = Storage.useQuiz(quizNumber)
  const numItems = Object.values(quiz).length
  const numCorrect = Object.values(quiz).reduce((total, currentValue) => {
    if (currentValue) total += 1
    return total
  }, 0)
  const pctCorrect = Math.floor((100 / numItems) * numCorrect)

  return (
    <Flex
      flexDirection={["column"]}
      maxWidth={["100%", "tablet", "desktop"]}
      mx={"auto"}
      px={["16px", "32px"]}
      mt={[3, 4, 5]}
      flexGrow={1}
      minHeight={"100%"}
    >
      <Flex
        flexDirection={"column"}
        width={["100%"]}
        maxWidth={"600px"}
        mx={"auto"}
      >
        <Text
          fontFamily={"AdineuePROCondRegular"}
          fontSize={["16px", "20px"]}
          lineHeight={["19px", "22px"]}
          color={"black"}
        >
          LESSON {quizNumber}
        </Text>
        <Heading textAlign={"left"}>QUIZ RESULTS</Heading>
        <Flex flexDirection="column" alignItems={"center"}>
          <Flex
            height={["192px", "192px", "266px"]}
            minHeight={["192px", "192px", "266px"]}
            minWidth={["192px", "192px", "266px"]}
            width={["192px", "192px", "266px"]}
            justifyContent={"center"}
            alignItems={"center"}
            my={6}
            css={css`
              border: 16px solid #000000;
              border-radius: 50%;
            `}
          >
            <Heading
              fontFamily={"AdineuePROBlackWeb"}
              fontSize={["64px", "64px", "100px"]}
              lineHeight={["76px", "76px", "118px"]}
            >
              {numCorrect}/{numItems}
            </Heading>
          </Flex>
          <Heading mb={6}>
            {pctCorrect}% CORRECT <br/>
            {pctCorrect >= 80 && <span>GREAT JOB!</span>}
          </Heading>
          <Heading>YOU’VE COMPLETED LESSON {quizNumber}</Heading>
          {text && <Text textAlign={"center"}>{text}</Text>}
          <Box width={"100%"} my="5">
            <Link
              to={nextLink}
              css={css`
                text-decoration: none;

                &:hover {
                  text-decoration: none;
                }
              `}
            >
              <Button my="5" width={"100%"}>
                NEXT LESSON
              </Button>
            </Link>
          </Box>
        </Flex>
      </Flex>
      <Copyright mt={7}/>
    </Flex>
  )
}

export default Quiz
export { QuizResults }
